import React, { useCallback } from "react";

import { Button } from "@/components/ui";
import { Account_Api_EntryPointEnum } from "@/graphql-schema-types.generated";
import { useOpenPopupCalendly, useSchemaLdJsonToContent } from "@/hooks";
import { useSetEntryPoint } from "@/lib/saveEntryPoint";

import Subtitle from "../Subtitle";
import Service from "./Service";
import { MortgagesProps } from "./types";

import styles from "./styles.module.scss";

const Mortgages = ({ content, schemaProductList }: MortgagesProps) => {
  const openPopupCalendly = useOpenPopupCalendly(content.button_url);
  const { setEntryPoint } = useSetEntryPoint();
  const productListSchema = useSchemaLdJsonToContent(
    schemaProductList,
    "productListSchema"
  );

  const handleBookACall = useCallback(() => {
    setEntryPoint(Account_Api_EntryPointEnum.EntryPointBookMortgageAdvice);
    openPopupCalendly();
  }, [openPopupCalendly, setEntryPoint]);

  return (
    <section className={styles.container}>
      {productListSchema}
      <div className={styles.bg1} />
      <div className={styles.bg2} />
      <div className={styles.content}>
        <Subtitle className={styles.title} content={content.title} />
        <p className={styles.text}>{content.text}</p>

        <Button
          fullWidth
          className={styles.ctaButtonMobile}
          onClick={handleBookACall}
          shape="rounded"
          size="lg"
          theme="primary"
        >
          {content.button_text}
        </Button>

        <div className={styles.inner}>
          <div className={styles.cta}>
            <div
              className={styles.avatar}
              style={{ backgroundColor: content.manager_background }}
            >
              <picture>
                <source
                  srcSet={`${content.manager_avatar.url}.webp`}
                  type="image/webp"
                />
                <source srcSet={content.manager_avatar.url} type="image/png" />
                <img
                  alt={content.manager_text}
                  decoding="async"
                  loading="lazy"
                  src={content.manager_avatar.url}
                />
              </picture>
            </div>
            <div className={styles.caption}>{content.manager_text}</div>
            <Button
              onClick={handleBookACall}
              shape="rounded"
              size="lg"
              theme="primary"
            >
              {content.button_text}
            </Button>
          </div>
          <div className={styles.services}>
            {content.services.map((s) => (
              <Service key={s.title} item={s} />
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Mortgages;
