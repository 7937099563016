import React from "react";

import cn from "classnames";

import { SubtitleProps } from "./types";

import styles from "./styles.module.scss";

const Subtitle = ({ className, content }: SubtitleProps) => (
  <h2
    dangerouslySetInnerHTML={{ __html: content }}
    className={cn(styles.subtitle, className)}
  />
);

export default Subtitle;
