import React from "react";

import Link from "next/link";

import { IMAGE_SIZE } from "./constants";
import { ServiceProps } from "./types";

import styles from "./styles.module.scss";

const Service = ({ item }: ServiceProps) => (
  <Link href={item.url} prefetch={false}>
    <a className={styles.service}>
      <img
        alt={item.title}
        className={styles.serviceImage}
        height={IMAGE_SIZE}
        loading="lazy"
        src={item.image}
        srcSet={`${item.image}, ${item.image2x} 2x`}
        width={IMAGE_SIZE}
      />
      <div>
        <div className={styles.serviceTitle}>{item.title}</div>
        <div className={styles.serviceDescription}>{item.description}</div>
      </div>
    </a>
  </Link>
);

export default Service;
